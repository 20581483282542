import { FilterDefinition, ScopeDto, Sort, WIDGET_PAGE_SIZE_OPTIONS } from "api-shared";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MeasuresTable, { IMeasuresTableProps } from "../../components/measures/MeasuresTable";
import { useMeasures } from "../../domain/measure/list";
import { useFilterValidation } from "../../hooks/useFilterValidation";
import { ApiError } from "../../lib/api";
import { getTranslatedErrorMessage } from "../../lib/error";
import WidgetError from "../../view/dashboards/WidgetError";

interface IMeasuresWidgetProps extends Omit<IMeasuresTableProps, "pageSize" | "onPageChanged" | "onSortChanged" | "data"> {
    dataKey: string;
    filter?: FilterDefinition;
    scope?: ScopeDto;
    onSortChanged?: IMeasuresTableProps["onSortChanged"]; // make optional
    enableQueries?: boolean;
}

const MeasuresWidget = ({
    filter,
    orderBy,
    sort,
    scope,
    columns,
    dataKey,
    onSortChanged,
    enableQueries = true,
    pageIndex,
    ...otherProps
}: IMeasuresWidgetProps) => {
    const [sorting, setSorting] = useState({ orderBy: orderBy ?? columns[0], sort: sort ?? Sort.DESCENDING });
    const [pagination, setPagination] = useState({
        pageSize: otherProps.pageSizeOptions?.[0] ?? WIDGET_PAGE_SIZE_OPTIONS[0],
        page: 0,
    });
    const { t: translate } = useTranslation();

    const { validate } = useFilterValidation(enableQueries);
    const isValidFilter = filter !== undefined ? validate?.(filter) : true;

    const measuresQuery = useMeasures(dataKey, {
        filter,
        pageSize: pagination.pageSize,
        page: pageIndex ?? pagination.page,
        ...sorting,
        scope,
        enabled: enableQueries && isValidFilter,
        meta: {
            // ignore error notification because errors are shown inline
            ignoreErrors: true,
        },
    });

    const hasErrorState = measuresQuery.fetchStatus === "idle" && validate !== undefined && !isValidFilter;

    useEffect(() => {
        // sync local sorting state with props, so changing props will also be reflected in table
        setSorting({ orderBy, sort });
        // reset pagination to 0, if a different page was selected before
        setPagination((old) => (old.page !== 0 ? { ...old, page: 0 } : old));
    }, [orderBy, sort]);

    const updateSort = useCallback(
        (newOrderBy: string, newSort: Sort) => {
            setSorting({ orderBy: newOrderBy, sort: newSort });
            // reset page to first page, when sorting changes
            setPagination((old) => (old.page !== 0 ? { ...old, page: 0 } : old));
            if (onSortChanged) {
                onSortChanged(newOrderBy, newSort);
            }
        },
        [onSortChanged],
    );

    const updatePage = useCallback((newPageSize: number, newPage: number) => {
        setPagination({ page: newPage, pageSize: newPageSize });
    }, []);

    if (measuresQuery.isError && measuresQuery.error instanceof ApiError) {
        return <WidgetError>{getTranslatedErrorMessage(measuresQuery.error, translate)}</WidgetError>;
    }

    if (hasErrorState) {
        return <WidgetError />;
    }

    return (
        <MeasuresTable
            {...sorting}
            data={measuresQuery.data}
            pageSize={pagination.pageSize}
            columns={columns}
            onSortChanged={updateSort}
            onPageChanged={updatePage}
            pageSizeOptions={WIDGET_PAGE_SIZE_OPTIONS}
            isFetching={!measuresQuery.isSuccess}
            enableQueries={enableQueries}
            {...otherProps}
        />
    );
};

MeasuresWidget.propTypes = {
    filter: PropTypes.object,
    defaultOrderBy: PropTypes.string,
    defaultSort: PropTypes.oneOf(Object.values(Sort)),
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataKey: PropTypes.string.isRequired,
};

export default MeasuresWidget;
