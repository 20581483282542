import { AclNamespaces, AclPermissions, FieldTypes, FilterDefinition } from "api-shared";
import { useMeasureFieldDefinitionsQuery } from "../domain/filters";
import { useUserHasPermissionQuery } from "../domain/permissions";
import { useCurrentUserId } from "../domain/users";

type UseFilterValidationResult = {
    validate?: (filter: FilterDefinition) => boolean;
};

export const useFilterValidation = (enabled = true): UseFilterValidationResult => {
    const fieldsQuery = useMeasureFieldDefinitionsQuery(enabled);
    const allFields = Object.values(fieldsQuery.data ?? {});
    const userFields = allFields.filter((field) => field.type === FieldTypes.User || field.type === FieldTypes.Users);
    const userFieldNames = new Set(userFields.map((field) => field.name));

    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const currentUserId = useCurrentUserId();

    if (!fieldsQuery.isSuccess || !hasPersonFilterPermissionQuery.isSuccess) {
        return {};
    }

    const validate = (filter: FilterDefinition): boolean => {
        const hasInvalidCondition = filter.some((condition) => {
            if (userFieldNames.has(condition.field) && !hasPersonFilterPermissionQuery.data.hasPermission) {
                // Only allowed to filter by yourself ("0" is an alias for your user ID)
                return (
                    condition.values.length !== 1 ||
                    (condition.values[0].toString() !== currentUserId?.toString() && condition.values[0].toString() !== "0")
                );
            }

            return false;
        });

        return !hasInvalidCondition;
    };

    return { validate };
};
